import React from 'react'
import style from 'styled-components'
import {Link} from "react-router-dom";
import { Outlet } from "react-router-dom";

function Product() {
  return (
    <ProductContainer>
        <Products>
            <Left>  
                <Top>
                    <Title>
                        <h3><a href="/">PRODUCTS</a></h3>
                    </Title>
                    <List>
                        <ul>
                            <li><Link to="/products/kitchenCabinets">Kitchen Cabinents</Link></li>
                            <li><Link to="/products/bathroomVanities">Bathroom Vanities</Link></li>
                            <li><Link to="/products/counterTops">Granite Countertops</Link></li>
                            <li><Link to="/products/tiles">Kitchen and Bath Tiles</Link></li>
                            <li><Link to="/products/lighting">Lighting</Link></li>
                        </ul>
                    </List>
                </Top>
                <Top>
                    <Title>
                        <h3><a href="/">SERVICES</a></h3>
                    </Title>
                    <List>
                        <ul>
                            <li><Link to="/products/services">Measurements</Link></li>
                            <li><Link to="/products/services">Design</Link></li>
                            <li><Link to="/products/services">Delivery</Link></li>
                            <li><Link to="/products/services">Installation</Link></li>
                        </ul>
                    </List>
                </Top>
            </Left>
            <Right>
                <Outlet/>
            </Right>
        </Products> 
    </ProductContainer>
  )
}

const ProductContainer = style.div`
    padding:110px 0;
`
const Products = style.div`
    max-width:1170px;
    padding: 0 30px;
    margin:0 auto;
    display:flex;

    @media (max-width:900px){
        flex-direction: column;
    }
`
const Left =style.div`
    width:25%;
    @media (max-width:900px){
        width:100%;
    }
`

const Top=style.div`
    position:relative;
    margin-bottom:60px;
    @media (max-width:900px){
        margin-left:40px;
    }
`
const Title = style.div`
    position: relative;
    padding-bottom: 65px;
    &:after{
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background: #77bc25;
        position: absolute;
        right: 0px;
        top: 20px;
        z-index: 1;
    }
    h3{
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: #1b1313;
        padding-right: 5px;
        letter-spacing: 2px;
        background: #fff;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 2;
        a{
            outline: none;
            text-decoration: none;
            color: #07D;
            text-decoration: none;
            cursor: pointer;
        }
    }
`
const List = style.div`
    ul{
        list-style:none;
        li{
            padding: 15px 0px;
            border-bottom: 1px solid #ededed;
            &:nth-child(1){
                padding: 0 0 15px 0;
            }
            a{
                display: block;
                line-height: 11px;
                font-family: 'Roboto', sans-serif;
                font-size: 16px;
                color: #676767;
                font-weight: 600;
                outline: none;
                text-decoration: none;
            }
        }
    }

`
const Right =style.div`
    padding-left:35px;
    width:75%;
    @media (max-width:900px){
        width:100%;
    }
`
export default Product