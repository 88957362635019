import React from 'react'
import Hero from './components/HeroSection'
import Design from './components/Design'
import Success from './components/Success'
import Last from './components/Last'

function About() {
  return (
    <>
    <Hero/>
    <Design/>
    <Success/>
    <Last />
    </>
  )
}

export default About