import React from 'react'
import style from 'styled-components'
function Partner() {
  return (
    <Container>
        <Partners>
            <Inner>
                <h3>OUR PARTNERSHIPS</h3>
                <p>At Federal Kitchen and Lighting, we work to create close working relationships with professionals in the kitchen and bath remodeling industry. By providing special wolesale pricing, extremely fast (3 busines days) turnaround on cabinetry and countertops, and pick up and delivery anywhere in Metro Boston and New England we have built beneficial and reliable partnerships with many contractors and designers.</p>
                <p>In addition to discounts and expedited delivery times, we maintain an active referral network to match our retail customers with contractors and designers in their area. To take advantage of the spceial pricing and referrals, and to receive occasional updates on specials and new product arrivals, please fill out the short form below and we will follow up with you within the next 24 hours.</p>
            </Inner>
        </Partners>
    </Container>
  )
}

const Container = style.div`
    padding-top:110px;
    text-align:center;
`
const Partners = style.div`
    max-width:1170px;
    padding: 0 30px;
    margin:0 auto;
`
const Inner = style.div`
    width:66%;
    margin:0 auto;
    @media (max-width:800px){
        width:100%;
    }
    h3{
        font-size: 36px;
        line-height: 35px;
        font-weight: bold;
        color: #1b1313;
        margin-bottom: 10px;
    }
    p{
        font-size: 16px;
        color: #676767;
        line-height: 25px;
        font-weight: 400;
        margin-bottom: 24px;
    }
`
export default Partner