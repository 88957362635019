import React from 'react'
import 'react-slideshow-image/dist/styles.css'
import style from 'styled-components'
import { Zoom } from 'react-slideshow-image';
import Kitch_1 from '../../../../assets/img/kitchen_1.jpg'
import Kitch_2 from '../../../../assets/img/kitchen-2-min.jpg'
import Bathroom_1 from '../../../../assets/img/bathroom-1-min.jpg'
import Bathroom_2 from '../../../../assets/img/bathroom-2-min.jpg'
import Image_7 from '../../../../assets/img/image7.jpeg'
import Image_3 from '../../../../assets/img/image3.jpeg'

import './index.css'

function SlideShow() {

    const images = [
        Image_7,
        Kitch_1,
        Image_3,
        Kitch_2,
        Bathroom_1,
        Bathroom_2,
    ];

  return (
    <Zoom
    scale={1.4} 
    arrows={true}
    pauseOnHover={false}
    duration={3000}
    >
        {images.map((each, index) => (
                <SlideContainer key={index}>
                    <img alt="Slider" src={each} />
                </SlideContainer>
        ))}
    </Zoom>
  )
}

const SlideContainer =style.div`
    max-height:680px;
    img{
        object-fit: cover;
        width: 100%;
        height:100%;
    }
`

export default SlideShow;