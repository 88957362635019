import React from 'react'
import Hero from '../conponents/HeroSection'
import Product from '../conponents/Product'
import Last from '../conponents/Last'
function Layout() {
  return (
    <>
      <Hero />
      <Product />
      <Last />
    </>
  )
}

export default Layout