import React from 'react'
import style from 'styled-components'
import HeroImage from '../../../assets/img/inner-banner.jpg'
function Hero() {
  return (
    <HeroContainer image={HeroImage}>
        <HeroInfo>
            <h2>FOR CONTRACTORS AND DESIGNERS</h2>
        </HeroInfo>
    </HeroContainer>
  )
}

const HeroContainer =style.div`
    padding:110px 0;
    background:url(${props=>props.image});
    background-size:cover;
`
const HeroInfo = style.div`
    text-align:center;
    h2{
        color: #ffffff;
        font-weight: bold;
        font-size: 36px;
        line-height: 36px;
        letter-spacing: 1px;
        margin-bottom: 7px;
        text-transform: uppercase;
    }
`

export default Hero