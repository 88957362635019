import React from 'react'
import Layout from './Layout';import {
  Route,
  Routes
} from "react-router-dom";
import Kitchen from './conponents/KitchenCabinets'
import Boxes from './conponents/Boxes';
import BathroomVanities from './conponents/BathroomVanities';
import CounterTops from './conponents/CounterTops';
import Tiles from './conponents/Tiles';
import Lighting from './conponents/Lighting';
import Services from './conponents/Services'

function ProductRouter() {
  return (
    <Routes>
        <Route path='/' element={<Layout />}>
            <Route path='/' element ={<Boxes />} /> 
            <Route path='/kitchenCabinets' element ={<Kitchen />} />     
            <Route path='/bathroomVanities' element ={<BathroomVanities />} />     
            <Route path='/counterTops' element ={<CounterTops />} />     
            <Route path='/tiles' element ={<Tiles />} />     
            <Route path='/lighting' element ={<Lighting />} />     
            <Route path='/services' element ={<Services />} />     
        </Route>
    </Routes>
  )
}

export default ProductRouter;