import React from 'react'
import style from 'styled-components'
import Logo from '../../assets/img/route-nine-logo_5.png'
function Footer() {
  return (
    <FootContainer>
        <FooterTop>
            <FooterInfo>
                <Left>
                    <LogoContainer>
                        <a href="/"><img src={Logo} alt="Company Logo" /></a>
                    </LogoContainer>
                    <p>429 Worcester Rd. Framingham, MA 01701</p>
                    <p>Tel: (508) 433-6321</p>
                    <p>Fax: (617) 812-8030</p>
                </Left>
                <Middle>
                    <div>
                        <h3>PRODUCTS</h3>
                        <ul>
                            <li><a href='/products/kitchenCabinets'>Cabinets</a></li>
                            <li><a href='/products/bathroomVanities'>Bathroom Vanities</a></li>
                            <li><a href='/products/counterTops'>Countertops</a></li>
                            <li><a href='/products/tiles'>Kitchen and Bath</a></li>
                            <li><a href='/products/lighting'>Lighting</a></li>
                        </ul>
                    </div>
                    <div>
                        <h3>SERVICES</h3>
                        <ul>
                            <li><a href='/products/services'>Consultation</a></li>
                            <li><a href='/products/services'>Measurements</a></li>
                            <li><a href='/products/services'>Layout & Design</a></li>
                            <li><a href='/products/services'>Delivery</a></li>
                            <li><a href='/products/services'>Contractor services</a></li>
                            <li><a href='/products/services'>Pricing</a></li>
                        </ul>
                    </div>
                </Middle>
                <Right>
                    <p>Route Nine is a retail and wholesale store located in Framingham, MA, specializing in sales and installation of factory-direct kitchen cabinets, glass shower doors and enclosures, bathroom vanities, granite countertops.</p>
                    <p>Serving contractors and residential customers in Metro Boston, MA, South Shore, Quincy, Massachusetts, Braintree, Milton, Randolph, Weymouth, Hingham and beyond.</p>
                </Right>
            </FooterInfo>
        </FooterTop>
        <FooterBottom>
            <CopyRight>
                <p>Copyright Route Nine 2022. All Rights Reserved.</p>
                <p>Website created by OursCat</p>
            </CopyRight>
        </FooterBottom>
    </FootContainer>
  )
}

const FootContainer = style.div`
    padding:30px 0px 0px;
    background-color: #160e0e;
`
const FooterTop = style.div`
    max-width:1140px;
    margin:0 auto;
    padding-botton:30px;
`
const FooterInfo = style.div`
    display:flex;
    @media (max-width:765px){
        flex-direction:column;
        padding-left:25px;
      }
`
const Left = style.div`
    width:33%;
    color:white;
    padding-left:30px;
    font-size:14px;
    p{
        line-height:24px;
    }
    @media (max-width:765px){
        padding-left:0px;
        margin-bottom:30px;
        width:60%;
      }
`
const LogoContainer = style.div`
    margin: 3px 0px 14px;
    width:300px;
    cursor:pointer;
    img{
        width:66%;
    }
    @media (max-width:765px){
        width:350px;
        img{
            width:100%;
        }
    }
`
const Middle = style.div`
    width:33%;
    display:flex;
    @media (max-width:765px){
       flex-direction:column;
       div{
        margin-bottom:50px;
       }
    }
    div{
        flex:1;
    }
    h3{
        color:white;
        margin-bottom:20px;
    }
    ul{
        list-style:none;
        li{
            a{
                text-decoration:none;
                font-size: 14px;
                line-height: 28px;
                color: #cccccc;
                display: block;
                &:hover{
                    color:#F38604;
                }
            }
        }
    }
`
const Right = style.div`
    width:33%;
    font-size: 14px;
    line-height: 24px;
    color: #cccccc;
    margin-top:44px;
    @media (max-width:765px){
        width:80%;
        margin-top:0 !important;
    }
`
const FooterBottom = style.div`
    padding: 10px 0;
    background-color: #100909;
    color: #cccccc;
`
const CopyRight = style.div`
    max-width:1140px;
    margin:0 auto;
    padding-left:30px;
    p{
        display: block;
        font-size: 14px;
        color: #cccccc;
        line-height: 36px;
    }
@media (max-width:765px){
    padding-left:25px;
  } 
`
export default Footer