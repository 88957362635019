import React from 'react'
import style from 'styled-components'

import KitchenCover from '../../../../assets/img/sv_1.jpg'
import ShowerCover from '../../../../assets/img/sv_2.jpg'
import BathCover from '../../../../assets/img/sv_3.jpg'
import CounterCover from '../../../../assets/img/sv_4.jpg'


function Services() {
  return (
    <>
    <Box>
        <ImageContainer>
            <img src={KitchenCover} alt="cover" />
        </ImageContainer>
        <Content>
            <h3>KITCHEN CABINETS</h3>
            <p>A great selection of factory-direct quality cabinetry at affordable prices. We will help you find the right kitchen cabinets to fit your taste, your style, your house, and your budget.</p>
            <br />
            <div>
                <a href="/contactUs">CALL NOW</a>
            </div>
        </Content>
    </Box>
    <Box>
        <ImageContainer>
            <img src={ShowerCover} alt="cover" />
        </ImageContainer>
        <Content>
            <h3>SHOWER DOORS & ENCLOSURES</h3>
            <p>High-quality, stunning glass shower door and enclosure styles that are ready to go home with you today. Superior quality and lower prices compaired to major retailers. Large assortment of parts and completed full sets in stock every day.</p>
            <br />
            <div>
                <a href="/contactUs">CALL NOW</a>
            </div>
        </Content>
    </Box>
    <Box>
        <ImageContainer>
            <img src={BathCover} alt="cover" />
        </ImageContainer>
        <Content>
            <h3>BATHROOM VANITIES</h3>
            <p>Turn-key preassembled and custom-made bathroom vanities. Contemporary and traditional styles. Granite, Quarts, Marble countertops.</p>
            <br />
            <div>
                <a href="/contactUs">CALL NOW</a>
            </div>
        </Content>
    </Box>
    <Box>
        <ImageContainer>
            <img src={CounterCover} alt="cover" />
        </ImageContainer>
        <Content>
            <h3>KITCHEN CABINETS</h3>
            <p>Natural stone countertops bring sophistication and character to any room. Choose from a wide variety of colors and patterns and make you home shine.</p>
            <br />
            <div>
                <a href="/contactUs">CALL NOW</a>
            </div>
        </Content>
    </Box>
</>
  )
}

const Box = style.div`
    background: #f8f8f8;
    display:flex;
    @media (max-width:900px){
        flex-direction:column;
    }
`
const ImageContainer = style.div`
    width: 265px;
    img{
        width:265px;
    }
`
const Content = style.div`
    width: 66.666%;
    padding: 33px 50px 40px 50px;
    display: table-cell;
    vertical-align: top;
    text-align: left;
    border-top: none;
    height:280px;
    @media (max-width:900px){
        width: 100%;
        padding:33px 50px 40px 0px;
    }
    h3{
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        color: #1b1313;
        line-height: 25px;
    }
    p{
        padding-top: 4px;
        padding-bottom: 17px;
        line-height: 26px;
        font-size: 16px;
        color: #444;
    }
    a{
        background: #F48805;
        border: 1px solid #F48805;
        transition: all .5s ease;
        color: #ffffff;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 700;
        padding: 10px 30px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        &:hover{
            background: #bc7c32;
        }
`


export default Services