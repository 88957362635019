import React ,{ useRef }from 'react'
import style from 'styled-components'
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
function SendMessage() {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_oipu3hu', 'template_pu7ce7q', form.current, 'gf1C5Bh2YHN-an0Ge')
          .then((result) => {
              console.log(result.text);
              Swal.fire({
                icon: 'success',
                title: 'Message Sent Successfully'
              })
          }, (error) => {
              console.log(error.text);
              Swal.fire({
                icon: 'error',
                title: 'Ooops, something went wrong',
                text: error.text,
              })
          });
          e.target.reset()
      };

  return (
    <Container>
        <Message>
                <Title>
                <h1>
                    SEND US A MESSAGE
                </h1>
                <p>Requesting information or a quote? Have questions or comments? Send us a message.
                </p>
                <BorderImg>
                </BorderImg>
            </Title>
        </Message>
        <FormContainer>
            <form ref={form} onSubmit={sendEmail}>
                <InputContainer>
                    <input type="text" name ='user_name' placeholder='Your Name'/>
                    <input type="text" name ='user_phone'placeholder='Phone Number'/>
                </InputContainer>
                <Email type="email" name ='user_email' placeholder='Email'/>
                <Textarea placeholder='Comment' name='message' />
                <ButtonContainer>
                    <Button type="submit">SUBMIT MESSAGE</Button>
                </ButtonContainer>
            </form>
        </FormContainer>
    </Container>
  )
}

const Container = style.div`
    padding: 0px 0 110px;
`
const Message = style.div`
    padding:0 15px;
    max-width:1150px;
    margin:0 auto;
    text-align:center;
`

const Title = style.div`
    margin-bottom:70px;
    h1{
        margin-bottom:10px;
        font-size:35px;
        letter-spacing:3px;
        font-weight:bold;
    }
    p{
        margin-bottom:24px;
    }
`
const BorderImg = style.div`
 &:after{
    content: '';
    display: block;
    margin: 0 auto;
    margin-bottom: 70px;
    height: 4px;
    width: 47px;
    border-radius: 4px;
    background-color: #F38704;
 }
`
const FormContainer=style.div`
    font-size:14px;
    max-width:900px;
    padding:0 30px;
    margin:0 auto;
    p{
        margin: 20px 0px;
    }
`
const InputContainer = style.div`
        input{
            width:45%;
            height:45px;
            border: 1px solid #ddd;
            margin-right:30px;
            padding: 4px 6px;
            margin-top:20px;
            @media (max-width:675px){
                width:100%;
            }
        }
        margin-bottom:30px;
`
const Email = style.input`
    width:100%;
    padding: 4px 6px;
    border: 1px solid #ddd;
    background: #fff;
    color: #444;
    height:45px;
`
const Textarea = style.textarea`
    width:100%;
    height:150px;
    border: 1px solid #d7d7d7;
    padding: 4px 6px;
    margin-top:20px;
`
const ButtonContainer = style.div`
        width:100%;
        text-align:center;
        margin-top:20px;
`
const Button = style.button`
        height:40px;
        padding: 10px 20px;
        background: #F38704;
        border: 1px solid #77bc25;
        color:white;
`
export default SendMessage