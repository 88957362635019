import React from 'react'
import style from 'styled-components'
import SendMessage from './SendMessage'
import Hero from './HeroSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function ContactUs() {
  return (
    <>
    <Hero/>
    <Container>
        <Contact>
            <Top>
                <h3>TALK TO US</h3>
                <p>Whether you are building a brand new kitchen or bathroom or sprucing up an old one. Whether you already have a plan or are looking for help to make one. Whether you are a contractor or a home owner, we have products, people, and resources to make your project a success. Contact us today to see what we can do for you.</p>
            </Top>
            <Boxes>
                <Box>
                    <span><FontAwesomeIcon icon={faMapLocationDot} size="2x"/></span>
                    <p>429 worcester Rd. Framingham, MA</p>
                </Box>
                <Box2>
                    <span><FontAwesomeIcon icon={faEnvelope} size="2x"/></span>
                    <p>contact@route-9.com</p>
                </Box2>
                <Box>
                    <span><FontAwesomeIcon icon={faPhoneAlt} size="2x"/></span>
                    <p>(617) 820-9189</p>
                </Box>
            </Boxes>
        </Contact>
    </Container>
    <SendMessage/>
    </>
  )
}

const Container = style.div`
    padding:110px  0px;

`
const Contact =style.div`
    max-width:1170px;
    padding:0 30px;
    margin:0 auto;
`
const Top = style.div`
    width:66%;
    margin:0 auto;
    text-align:center;
    @media (max-width:600px){
        width:80%;
    }
    h3{
        font-size: 36px;
        line-height: 35px;
        font-weight: bold;
        color: #1b1313;
        margin-bottom: 10px;
    }
    p{
        font-size: 16px;
        color: #676767;
        line-height: 25px;
        font-weight: 400;
        margin-bottom: 24px;
    }
`
const Boxes = style.div`
    display:flex;
    @media (max-width:1000px){
       flex-direction:column;
    }
`
const Box = style.div`
    flex:1; 
    padding-top:45px;
    padding-bottom:35px;
    text-align:center;
    background: #f3f3f3;
    margin:10px;
    p{
        margin-top:10px;
    }
`
const Box2 = style.div`
    flex:1; 
    padding-top:45px;
    padding-bottom:35px;
    text-align:center;
    background: #eeeeee;
    margin:10px;
    p{
        margin-top:10px;
    }
`

export default ContactUs