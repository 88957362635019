import React from 'react'
import style from 'styled-components'
import BorderImage from '../../../../assets/img/border-line.png'

function Location() {
  return (
    <LocationContainer>
        <Locations>
            <Title>
                <h1>
                    OUR LOCATION
                </h1>
                <p>Beautiful showrooms are supported by fully-stocked warehouses throughout New England.
                </p>
                <BorderImg BorderImage={BorderImage}>
                </BorderImg>
            </Title>
            <Address>
                <h3>FRAMINGHAM, MA</h3>
                <ul>
                    <li>
                        <strong>Route Nine Cabinetry & Countertop</strong>
                    </li>
                    <li>429 Worcester Rd. Framingham, MA 01701</li>
                    <li>508-433-6321</li>
                    <li>Mon-Sat 8am - 5pm</li>
                </ul>
            </Address>
        </Locations>
    </LocationContainer>
  )
}

const LocationContainer = style.div`
    background:gary;
    padding:110px 0;
`
const Locations = style.div`
    max-width:1150px;
    margin:0 auto;
    text-align:center;
`
const Title = style.div`
    margin-bottom:70px;
    h1{
        margin-bottom:10px;
        font-size:35px;
        letter-spacing:3px;
        font-weight:bold;
    }
    p{
        margin-bottom:24px;
        padding:0 15px;
    }
`
const BorderImg = style.div`
    
 &:after{
    content: '';
    display: block;
    background: url(${props=>props.BorderImage}) no-repeat center center;
    height: 27px;
    width: 100px;
    display: block;
    margin: 0 auto;
    margin-bottom: 70px;
 }
`

const Address = style.div`
    ul{
        list-style:none;
        li{
            line-height:36px;
        }
    }
`
export default Location