import React from 'react'
import style from 'styled-components'
import BorderImage from '../../../../assets/img/border-line.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag } from '@fortawesome/free-solid-svg-icons'
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons'
import { faHourglass2 } from '@fortawesome/free-solid-svg-icons'


function Success() {
  return (
    <SuccessContainer>
        <SuccessDiv>
            <InnerContainer>
                <Top>
                    <h3>OUR SUCCESS - OUR VALUES</h3>
                    <p>A company driven by passion, experience, knowledge and a desire to make every customer happy.</p>
                    <BorderImg BorderImage={BorderImage}>
                    </BorderImg>
                </Top>
                <Bottom>
                    <Attention>
                        <Left>
                            <h1>ATTENTION</h1>
                        </Left>
                        <Middle>
                            <Box><FontAwesomeIcon icon={faFlag} size="3x"/></Box>
                            <h1>EXPERIENCE</h1>
                        </Middle>
                        <Right>
                            <p>Route nine is a successful retail and storage facilities for kitchen and lightings. The company established a large network of suppliers and manufacturers for a kitchen design company that has a direct path from factory to the store.</p>
                        </Right>
                    </Attention>
                    <Attention>
                        <Left>
                            <h1>ACCURACY</h1>
                        </Left>
                        <Middle>
                            <Box><FontAwesomeIcon icon={faHourglass2} size="3x"/></Box>
                            <h1>KNOWLEDGE</h1>
                        </Middle>
                        <Right>
                            <p>The main purpose of the store is to make it an easy, convenient, and affordable one-stop kitchen design outlet for residential customers and contractors alike. The selection of products includes cabinetry, granite, marble and quartz counter tops, glass shower doors and enclosures.</p>
                        </Right>
                    </Attention>
                    <Attention>
                        <Left>
                            <h1>SERVICE</h1>
                        </Left>
                        <Middle>
                            <Box><FontAwesomeIcon icon={faNetworkWired} size="3x"/></Box>
                            <h1>NETWORK</h1>
                        </Middle>
                        <Right>
                            <p>Services such as consultation, design, delivery and installation are tailored to every customer's needs and budget. The things to do not change, however, are attention to every detail, quality and high service standards.</p>
                        </Right>
                    </Attention>
                    
                </Bottom>
            </InnerContainer>
        </SuccessDiv>
    </SuccessContainer>
  )
}

const SuccessContainer = style.div`
    background: #f3f3f3;
    padding:110px 0;

`
const BorderImg = style.div`
    
 &:after{
    content: '';
    display: block;
    background: url(${props=>props.BorderImage}) no-repeat center center;
    height: 27px;
    width: 100px;
    display: block;
    margin: 0 auto;
    margin-bottom: 70px;
 }
`
const SuccessDiv = style.div`
    max-width:1170px;
    padding: 0 30px;
    margin:0 auto;
`
const InnerContainer = style.div`

`
const Top =style.div`
    h3{
        font-size: 36px;
        line-height: 35px;
        font-weight: bold;
        color: #1b1313;
        margin-bottom: 10px;
        text-align: center;
    }
    p{
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        color: #676767;
        display: block;
        margin-bottom: 24px;
        text-align: center;
    }

`
const Bottom =style.div`
    max-width:950px;
    margin:0 auto;

`

const Attention = style.div`
    display:flex;
    align-items:center;
    @media (max-width:800px){
        flex-direction: column;
    }

`

const Left=style.div`
    flex:1;
    padding: 60px 0;
    h1{
        color: #676767;
        font-family: 'Roboto', sans-serif;
        font-size: 36px;
        font-weight: 800;
        text-align: center;
    }


`
const Middle = style.div`
    flex:1;
    h1{
        color: #676767;
        font-size: 20px;
        font-weight: 800;
        font-family: 'Roboto', sans-serif;
        text-align: center;
    }

`
const Right = style.div`
    flex:1; 
    p{
        font-size: 16px;
        color: #676767;
        line-height: 25px;
    }
`

const Box = style.div`
    color:rgb(204, 204, 204);
    text-align:center;
    transition: all 0.5s ease;
    margin-bottom:20px;
    &:hover {
        color: #F38704
    }
`

export default Success