import React from 'react'
import SlideShow from './components/SlideShow'
import Location from './components/Locations'
import Lighting from './components/Lighting'
import Kitchen from './components/Kitchen'
import FoundOutMore from './components/FoundOutMore'
import Different from './components/Different'
import Facilities from './components/Facilities'
import Last from './components/Last'
function Home() {
  return (
    <>
        <SlideShow />
        <Location />
        <Lighting />
        <Kitchen />
        <FoundOutMore />
        <Different />
        <Facilities />
        <Last />
    </>
    
  )
}

export default Home