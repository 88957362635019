import React from 'react'
import style from 'styled-components'

function Lighting() {
  return (
    <LightingContainer>
        <LightingSection>
            <h4>Dear customers, our Lighting Department is being restructured and lighting products are not available right now. Thank you for your understanding and our apologies for the inconvenience. Please call us with any questions.</h4>
            <div>
                <a href="/contactUs">CONTACT US</a>
            </div>
        </LightingSection>
    </LightingContainer>
  )
}

const LightingContainer = style.div`
    background-color: #f3f3f3;
    padding-top:35px;     
    padding-bottom:25px; 
`

const LightingSection = style.div`
    max-width:1140px;
    padding:0 15px;
    margin:0 auto;
    text-align:center;
    h4{
        font-family: 'Roboto', sans-serif;
        font-size:26px;
        color: #444;
        line-height:36px;
        font-weight:400;
    }
    div{
        margin-top:40px;
        a{
            background: #F48805;
            border: 1px solid #F48805;
            transition: all .5s ease;
            color: #ffffff;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 700;
            padding: 10px 30px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            text-decoration: none;
            cursor: pointer;
            &:hover{
                background: #bc7c32;
            }
        }
    }
`

export default Lighting