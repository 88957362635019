import Hero from "./HeroSection";
import React from 'react';
import Partner from "./PartnerShip";
import Benefits from "./Benefits";
import SignUp from "./GetPartner";


function Contractors() {
  return (
    <>
    <Hero/>
    <Partner/>
    <Benefits/>
    <SignUp/>
    </>
    
  )
}


export default Contractors