import React from 'react'
import style from 'styled-components'

function Last() {
  return (
    <LastContainer>
        <LastOne>
            <h3>DON'T DREAM ABOUT REMODELING, DO IT!</h3>
            <div>
                <a href="/contactUs">CONTACT US</a>
            </div>
        </LastOne>
    </LastContainer>
  )
}

const LastContainer = style.div`
    padding:110px 0;
    background-color: #f3f3f3;
`
const LastOne = style.div`
    max-width:1140px;
    margin:0 auto;
    text-align: center;
    h3{
        font-size: 36px;
        line-height: 35px;
        font-weight: bold;
        color: #1b1313;
        margin-bottom: 10px;
        text-transform: uppercase;
    }
    div{
        margin-top:40px;
        a{
            background: #F48805;
            border: 1px solid #F48805;
            transition: all .5s ease;
            color: #ffffff;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 700;
            padding: 10px 30px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            text-decoration: none;
            cursor: pointer;
            &:hover{
                background: #bc7c32;
            }
        }
    }
`
export default Last