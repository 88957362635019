import React from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from "react-router-dom";
import Layout from './Layout';
import Home from './pages/Home';
import About from './pages/About';
import Products from './pages/Products';
import Contractors from './pages/Contractors';
import ContactUs from './pages/ContactUs';
function router() {
  return (
    <Router>
        <Routes>
            <Route path='/' element={<Layout />}>
                <Route path='/' element ={<Home />} />
                <Route path='/about' element={<About/>} />
                <Route path='/products/*' element={<Products/>}>
                  <Route path='/products/*' element={<Products />} />
                </Route>
                <Route path='/contractors' element={<Contractors/>} />
                <Route path='/contactUs' element={<ContactUs/>} />
            </Route>
        </Routes>
    </Router>
  )
}

export default router