import React from 'react'
import style from 'styled-components'
import image_1 from '../../../../assets/img/bv_1.jpg'
import image_2 from '../../../../assets/img/bv_2.jpg'
import image_3 from '../../../../assets/img/bv_3.jpg'
import image_4 from '../../../../assets/img/bv_4.jpg'
import image_5 from '../../../../assets/img/bv_5.jpg'
import image_6 from '../../../../assets/img/bv_6.jpg'
import image_7 from '../../../../assets/img/bv_7.jpg'
import image_8 from '../../../../assets/img/bv_8.jpg'
import image_9 from '../../../../assets/img/bv_9.jpg'

function BathroomVanities() {
  return (
    <Container>
      <Row>
        <div>
          <img src={image_1} alt="gallery" />
        </div>
        <div>
          <img src={image_2} alt="gallery" />
        </div>
        <div>
          <img src={image_3} alt="gallery" />
        </div>
      </Row>
      <Row>
      <div>
          <img src={image_4} alt="gallery" />
        </div>
        <div>
          <img src={image_5} alt="gallery" />
        </div>
        <div>
          <img src={image_6} alt="gallery" />
        </div>
      </Row>
      <Row>
        <div>
          <img src={image_7} alt="gallery" />
        </div>
        <div>
          <img src={image_8} alt="gallery" />
        </div>
        <div>
          <img src={image_9} alt="gallery" />
        </div>
      </Row>
    </Container>
  )
}

const Container =style.div`
  
`
const Row = style.div`
  display:flex;
  margin-bottom:30px;
  div{
    width:30%;
    margin-left:35px;
    img{
      width:100%;
    }
  }
  @media (max-width:900px){
    flex-direction: column;
    div{
      margin-left:0px;
      width:100%;
      margin-bottom:40px;
    }
    width:100%;
}
`
export default BathroomVanities