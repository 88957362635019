
import React from 'react'
import style from 'styled-components'
import BorderImage from '../../../assets/img/border-line.png'

function Benefits() {
  return (
    <KitchenContainer>
        <Kitchens>
            <Title>
                <h1>
                CONTRACTOR BENEFITS
                </h1>
                <p>We value every professional partnership and work hard to make every transaction benefitial to our partners.
                </p>
                <BorderImg BorderImage={BorderImage}>
                </BorderImg>
            </Title>
            <TypeContainer>
                <Type>
                    <h4>WHOLESALE PRICING</h4>
                    <p>
                    All contractors and designers receive significant discounts on our factory-direct products.
                    </p>
                </Type>
                <Type>
                    <h4>3-DAY TURNAROUND</h4>
                    <p>
                    Yes, this is real. We deliver a fully assembled kitchen in 3 business days.
                    </p>
                </Type>
                <Type>
                    <h4>REFERRALS NETWORK</h4>
                    <p>
                    We rely on our partner network to recommend kitchen remodel professionals to our retail customers.
                    </p>
                </Type>
            </TypeContainer>
        </Kitchens>
    </KitchenContainer>
  )
}

const KitchenContainer = style.div`
    background:gary;
    padding:110px 0 80px;
`
const Kitchens = style.div`
    max-width:1150px;
    margin:0 auto;
    text-align:center;
`
const Title = style.div`
    padding:0 30px;
    margin-bottom:70px;
    h1{
        margin-bottom:10px;
        font-size:35px;
        letter-spacing:3px;
        font-weight:bold;
    }
    p{
        margin-bottom:24px;
    }
`
const BorderImg = style.div`
    
 &:after{
    content: '';
    display: block;
    background: url(${props=>props.BorderImage}) no-repeat center center;
    height: 27px;
    width: 100px;
    display: block;
    margin: 0 auto;
    margin-bottom: 70px;
 }
`
const TypeContainer = style.div`
   max-width:1170px;
   display:flex;
   justify-content:center;
   @media (max-width:765px){
    flex-direction:column;
  }
`

const Type = style.div`
    padding-right:10px;
    padding-left:35px;
    width:25%;
    @media (max-width:765px){
       width:100%;
       height:130px;
      }
    h4{
        color: #1b1313;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;
        margin-bottom: 8px;
        text-align:left;
    }
    p{
        font-size: 16px;
        color: #676767;
        line-height: 26px;
        font-weight: 400;
        text-align:left;
    }
`



export default Benefits