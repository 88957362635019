import React ,{ useRef } from 'react'
import style from 'styled-components'
import BorderImage from '../../../assets/img/border-line.png'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

function SignUp() {

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_oipu3hu', 'template_t6l8ba9', form.current, 'gf1C5Bh2YHN-an0Ge')
          .then((result) => {
              console.log(result.text);
              Swal.fire({
                icon: 'success',
                title: 'Message Sent Successfully'
              })
          }, (error) => {
              console.log(error.text);
              Swal.fire({
                icon: 'error',
                title: 'Ooops, something went wrong',
                text: error.text,
              })
          });
          e.target.reset()
      };

  return (
    <Container>
        <SignUpContainer>
            <Title>
                <h1>
                GET BENEFITS
                </h1>
                <p>Sign up to become our valued partner.
                </p>
                <BorderImg BorderImage={BorderImage}>
                </BorderImg>
            </Title>
            <Form ref={form} onSubmit={sendEmail}>
                <input type="text" placeholder='Your Name' name='user_name'/>
                <input type="text" placeholder='Your Company Name' name='company_name'/>
                <input type="text" placeholder='Phone Number' name='phone_number'/>
                <input type="text" placeholder='Your Email' name='email'/>
                <TextArea name="message" rows="10" placeholder='Message (Optional)'>

                </TextArea>
                <ButtonContainer>
                    <Button>GET PARTNER BENEFITS</Button>
               </ButtonContainer>
            </Form>
        </SignUpContainer>
    </Container>
  )
}
const Container = style.div`
    background:gary;
    padding:0px 0 80px ;
`
const SignUpContainer = style.div`
    max-width:1150px;
    margin:0 auto;
    text-align:center;
`
const Title = style.div`
    margin-bottom:70px;
    h1{
        margin-bottom:10px;
        font-size:35px;
        letter-spacing:3px;
        font-weight:bold;
    }
    p{
        margin-bottom:24px;
    }
`
const BorderImg = style.div`
    
 &:after{
    content: '';
    display: block;
    background: url(${props=>props.BorderImage}) no-repeat center center;
    height: 27px;
    width: 100px;
    display: block;
    margin: 0 auto;
    margin-bottom: 70px;
 }
`
const Form = style.form`
    input[type=text]{
        width:40%;
        margin:20px;
        height:50px;
        padding:5px 10px;
        font-size:16px;
        border: 1px solid #ddd;
        @media (max-width:450px){
            width:80%;
        }
    }
    p{
        margin: 20px;
    }
    input[type=checkbox]{
        padding:5px 10px;
        color:red;
    }
 
`

const Check = style.input`
    margin-left:20px;
    margin-right:10px;
    width:20px;
    height:16px;
`

const TextArea = style.textarea`
    width:84%;
    border: 1px solid #ddd;
    padding:5px 10px;
`
const ButtonContainer = style.div`
    width:100%;
    text-align:center;
`
const Button = style.button`
    display:inline;
    background:rgb(235,135,4);
    border:1px solid rgb(235,135,4);
    padding:15px 25px;
    margin-top:20px;
    color:white;
    font-weight:700;
`
export default SignUp