import React from 'react'
import style from 'styled-components'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import F1 from '../../../../assets/img/f1.jpg'
import F2 from '../../../../assets/img/f2.jpg'
import F3 from '../../../../assets/img/f3.jpg'
import F4 from '../../../../assets/img/f4.jpg'
import F5 from '../../../../assets/img/f5.jpg'
import F6 from '../../../../assets/img/image1.jpeg'
import F7 from '../../../../assets/img/image2.jpeg'
import F8 from '../../../../assets/img/image4.jpeg'




const responsiveSettings = [
    {
        breakpoint: 800,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 500,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 200,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }
];

function Facilities() {
  return (
    <FacilitiesContainer>
        <Facilitie>
            <Title>
                <h1>
                    A LOOK AT OUR FACILITIES
                </h1>
                <p>Our showrooms and warehouses meet all your kitchen and bathroom design and remodeling needs.
                </p>
                <BorderImg>
                </BorderImg>
            </Title>
            <CardsContainer>
                <Slide indicators={false} responsive={responsiveSettings} pauseOnHover={false} >
                    <Card>
                        <img src={F6} alt="facilitiesImage" />
                    </Card>
                    <Card>
                        <img src={F7} alt="facilitiesImage" />
                    </Card>
                    <Card>
                        <img src={F8} alt="facilitiesImage" />
                    </Card>
                    <Card>
                        <img src={F1} alt="facilitiesImage" />
                    </Card>
                    <Card>
                        <img src={F2} alt="facilitiesImage" />
                    </Card>
                    <Card>
                        <img src={F3} alt="facilitiesImage" />
                    </Card>
                    <Card>
                        <img src={F4} alt="facilitiesImage" />
                    </Card>
                    <Card>
                        <img src={F5} alt="facilitiesImage" />
                    </Card>
                </Slide>
            </CardsContainer>
        </Facilitie>
    </FacilitiesContainer>
  )
}

const FacilitiesContainer = style.div`
    background:gary;
    padding:0 15px;
`
const Facilitie = style.div`
    padding:0 15px;
    max-width:1150px;
    margin:0 auto;
    text-align:center;
`
const Title = style.div`
    margin-bottom:70px;
    h1{
        margin-bottom:10px;
        font-size:35px;
        letter-spacing:3px;
        font-weight:bold;
    }
    p{
        margin-bottom:24px;
    }
`
const BorderImg = style.div`
 &:after{
    content: '';
    display: block;
    margin: 0 auto;
    margin-bottom: 70px;
    height: 4px;
    width: 47px;
    border-radius: 4px;
    background-color: #F38704;
 }
`
const CardsContainer = style.div`
   
`

const Card = style.div`
    margin: 0 10px;
    max-height:445px;
    img{
        width:99%;
        height:100%;
    }
`
export default Facilities