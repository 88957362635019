import React from 'react'
import style from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToiletPortable } from '@fortawesome/free-solid-svg-icons'
import { faSink } from '@fortawesome/free-solid-svg-icons'
import { faKitchenSet } from '@fortawesome/free-solid-svg-icons'

function Different() {
  return (
    <DifferentContainer>
        <Differents>
            <Title>
                <h1>
                    HOW WE ARE DIFFERENT
                </h1>
                <p>Exceptional merchandise selection. Extensive product knowledge. Extremely fast order fulfillment.
                </p>
                <BorderImg>
                </BorderImg>
            </Title>
            <Features>
                    <Feature>
                        <Box>
                            <FontAwesomeIcon icon={faKitchenSet} size='3x'/>
                        </Box>
                        <h4>KITCHEN CABINETS</h4>
                        <p>
                        We offer a great choice of traditional and innovative cabinet designs at affordable prices. With an incredible 3-day ready time.
                        </p>
                    </Feature>
                    <Feature>
                        <Box>
                            <FontAwesomeIcon icon={faSink} size='3x'/>
                        </Box>
                        <h4>BATHROOM VANITIES</h4>
                        <p>
                        A vanity is an essential part of any bathroom. Save time and money with exquisite bathroom vanity styles.
                        </p>
                    </Feature>
                    <Feature>
                        <Box>
                            <FontAwesomeIcon icon={faToiletPortable} size='3x'/>
                        </Box>
                        <h4>COUNTERTOPS</h4>
                        <p>
                        Granite, quarts, marble countertops will make your kitchen shine. The stone is cut and delivered in 3 business days.
                        </p>
                    </Feature>
            </Features>
        </Differents>
    </DifferentContainer>
  )
}

const DifferentContainer = style.div`
    background:gary;
    padding:110px 0;
`
const Differents = style.div`
    padding:0 15px;
    max-width:1150px;
    margin:0 auto;
    text-align:center;
`
const Title = style.div`
    margin-bottom:70px;
    h1{
        margin-bottom:10px;
        font-size:35px;
        letter-spacing:3px;
        font-weight:bold;
    }
    p{
        margin-bottom:24px;
    }
`
const BorderImg = style.div`
 &:after{
    content: '';
    display: block;
    margin: 0 auto;
    margin-bottom: 70px;
    height: 4px;
    width: 47px;
    border-radius: 4px;
    background-color: #F38704;
 }
`

const Features = style.div`
    max-width:1150px;
    display:flex;
    justify-content:center;
    text-align:center;
    @media (max-width:765px){
        flex-direction:column;
    }
`

const Feature =  style.div`
    padding-right:10px;
    padding-left:5px;
    width:33%;
    @media (max-width:765px){
        width:100%;
        height:200px;
    }
    h4{
        color: #1b1313;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        line-height: 20px;
        font-weight: 600;
        margin-bottom: 8px;
        
    }
    p{
        font-size: 16px;
        color: #676767;
        line-height: 26px;
        font-weight: 400;
        margin-bottom:30px;
    }
`
const Box = style.div`
    transition: all 0.5s ease;
    margin-bottom:20px;
    &:hover {
        color: #F38704
    }
`
export default Different