import React from 'react'
import style from 'styled-components'

function Design() {
  return (
    <DesignContainer>
        <Designs>
            <GridView>
                <GridLeft>
                    <h3>AWSOME DESIGN</h3>
                    <h6>Kitchen Cabintes, Counter Tops, Glass Showers</h6>
                    <p>At Federal Kitchen and Lighting, we proudly combine great pricing on factory-direct kitchen cabinets and counters with exquisite custom designs and flexible and professional service. We work with both contractor companies and residential customers and offer a wide choice of services from in-store and on-site consultation, measurements, delivery to design, and installation. No home design is complete without proper attention to details. That is why we carry a wide selection of cabinet styles and a great many varieties of natual stone couter tops.</p>
                    <div>
                        <a href="/contactUs">TALK TO US</a>
                    </div>
                </GridLeft>
                <Gridright>
                    <ul>
                        <li>
                            <h1>50+</h1>
                            <p>CABINET STYLES</p>
                        </li>
                        <li>
                            <h1>30+</h1>
                            <p>GRANITE PATTERNS</p>
                        </li>
                        <li>
                            <h1>20+</h1>
                            <p>ESTABLISHED VENDORS</p>
                        </li>
                        <li>
                            <h1>2000+</h1>
                            <p>HAPPY CUSTOMERS</p>
                        </li>
                    </ul>
                </Gridright>
            </GridView>
        </Designs>
    </DesignContainer>
  )
}

const DesignContainer = style.div`
    padding:110px 0px;
`
const Designs = style.div`
    padding: 0 30px;
    max-width: 1170px;
    margin:0 auto;
`
const GridView = style.div`
    display:flex;
    @media (max-width:800px){
        flex-direction: column;
    }
`
const GridLeft = style.div`
    padding-right:20px;
    flex:1;
    h3{
        font-size: 36px;
        line-height: 35px;
        font-weight: bold;
        color: #1b1313;
        margin-bottom: 10px;
        
    }
    h6{
        color: #F48805;
        font-size: 14px;
        
        font-weight: bold;
        letter-spacing: 3px;
        line-height: 15px;
    }
    p{
        margin: 40px 0px;
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        line-height: 25px;
        font-weight: 400;
        color: #676767;
        display: block;
    }
    a{
        background: #F48805;
        border: 1px solid #F48805;
        transition: all .5s ease;
        color: #ffffff;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 700;
        padding: 10px 30px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        &:hover{
            background: #bc7c32;
        }
    }
`
const Gridright = style.div`
    flex:1;
    margin-left:50px;
    @media (max-width:800px){
        margin-top:20px;
        margin-left:0px;
    }
    ul{
        li{
            display:flex;
            align-items:center;
            padding:20px 0;
            border-bottom:3px solid #F48805;
        }
        list-style:none;
        h1{
            color: #676767;
            font-family: 'Roboto', sans-serif;
            font-size: 36px;
            font-weight: bold;
            line-height: 50px;
            margin-right:30px;
            width:100px;
        }
        p{
            color:   #676767;
            font-size: 16px;
            line-height: 50px;
        }
    }
`

export default Design