import React, {useState} from 'react'
import style,{ keyframes } from 'styled-components'
import {Link} from "react-router-dom";
import { Squash as Hamburger } from 'hamburger-react'

function Menu() {
    const [isOpen, setOpen] = useState(false)
    const [show, setShow] = useState('none')
    const [close, setClose] = useState(false)

const handleclick=()=>{
    setShow('none')
    setOpen(false)
}
  return (
    <MenuContainer>
        <Nav>
            <UnorderList show={show} close={close}>
                <li onClick={handleclick}><Link to="/">HOME</Link></li>
                <li onClick={handleclick}><Link to="/about">ABOUT</Link></li>
                <li onClick={handleclick}><Link to="/products">PRODUCTS</Link></li>
                <li onClick={handleclick}><Link to="/products/services">SERVICES</Link></li>
                <li onClick={handleclick}><Link to="/contractors">FOR CONTRACTORS</Link></li>
                <li onClick={handleclick}><Link to="/contactUs">CONTACT</Link></li>
            </UnorderList>
        </Nav>
        <HumburgerContainer>
            <Hamburger toggled={isOpen} toggle={setOpen} size={20} onToggle={toggled=>{
                if(toggled){
                    setShow('block')
                    setClose(false)
                } else {
                    setShow('none')
                    setClose(true)
                }
            }}/>
        </HumburgerContainer>
    </MenuContainer>
  )
}

const MenuContainer = style.div`
    background-color: #111111;
    color:white;
    height:50px;
    overflow:hidden;
`

const HumburgerContainer = style.div`
    position:absolute;
    right:10px;
    display:none;
    @media (max-width:900px){
        display:block;
     }
`
const Nav = style.div`
    max-width:1150px;
    margin:0 auto;
    z-index:999;
    @media (max-width:1150px){
        max-width:950px;
    }
`
const Appear = keyframes`
    0%{
        transform:translateX(-100%);
        opacity:0;
    }
    100%{
        transform:translateX(0%);
        opacity:0.9;
    }
`
const Disappear = keyframes`
    0%{
        transform:translateX(0%);
        opacity:0.9;
    }
    100%{
        transform:translateX(100%);
        opacity:0;
    }
`
const UnorderList = style.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #111111;
    z-index:999;
    @media (max-width:900px){ 
        width:100%;
       position:absolute;
       top:190px; 
       transform:translateX(-100%);
       animation-name:${props=>props.show ==='block'?Appear:''};
       animation-name:${props=>props.close?Disappear:''};
       animation-duration: 1s;
       animation-timing-function: ease-in-out;
       animation-fill-mode: forwards;
    }
    li {
        float: left;
        @media (max-width:900px){
            float:none;
        }
        a{
            font-weight: 500;
            font-size:14px;
            display: block;
            color: white;
            text-align: center;
            padding: 0 25px;
            text-decoration: none;
            height:50px;
            line-height: 50px;
            letter-spacing: 1px;
            &:hover{
                background-color: #333333;
                color:rgb(235,135,4);
            }
        }
    }
`

export default Menu