import React from 'react'
import Logo from '../../assets/img/route-nine-logo_1.png'
import MapMark from '../../assets/img/mapMarkIcon.png'
import PhoneIcon from '../../assets/img/phoneIcon.png'
import Clock from '../../assets/img/clock2.png'
import style from 'styled-components'

function Header() {
  return (
    <HeaderContainer>
      <TopContainer>
        <LogoContainer>
          <a href="/"><img src={Logo} alt="Company Logo" /></a>
        </LogoContainer>
        <UnorderList>
            <List1><span><img src={MapMark} alt="map icon" /></span>429 worcester Rd. Framingham, MA 01701</List1>
            <List2><span><img src={PhoneIcon} alt="phone icon" /></span>(617) 820-9189</List2>
            <List3><span><img src={Clock} alt="clock icon" /></span>Mon-Sat 8-5</List3>
        </UnorderList>
      </TopContainer>
    </HeaderContainer>
  )
}
const HeaderContainer = style.div`
  height:140px;
  overflow:hidden;
`
const TopContainer = style.div`
  max-width:1200px;
  display:flex;
  
  margin:0 auto;
  @media (max-width:1150px){
    max-width:1000px;
  }
  @media (max-width:1000px){
    justify-content:center;
  }
  @media (max-width:765px){
    padding:0px;
    
  }
`
const LogoContainer = style.div`
  width:300px;
  cursor:pointer;
  img{
    width:66%;
    margin-left:25px;
  }
  @media (max-width:1000px){
    width:350px;
    img{
      width:100%;
      height:180px;
      margin-left:0px;
    }
  }
  @media (max-width:765px){
    width:350px;
    img{
      width:100%;
      height:200px;
      margin-left:0px;
    }
  }
  
`
const UnorderList = style.ul`
  color: #676767;
  flex:1;
  display:flex;
  justify-content:space-between;
  list-style-type: none;
  align-items:center;
  @media (max-width:1000px){
    display:none;
  }
`
const List1 = style.li`
  display:flex;
  align-items:center;
 span{
  margin-right:16px;
  img{
    width:15px;
  }
 }
 &:hover{
  color:#F38704;
 }
 @media (max-width:1000px){
  display:none;
}

`
const List2 = style.li`
display:flex;
align-items:center;
span{
  margin-right:16px;
  img{
    width:15px;
  }
 }
  &:hover{
    color:#F38704;
  }
  &:before{
    content: "";
    border-right:1px solid #ddd;;
    margin-right:40px;
    height: 10px;
    vertical-align: middle;
    @media (max-width:1120px){
      margin-right:10px;
    }
  }
  @media (max-width:1000px){
    display:none;
  }
`
const List3 = style.li`
display:flex;
align-items:center;
span{
  margin-right:16px;
  img{
    width:15px;
  }
 }
  &:hover{
    color:#F38704;
  }
  &:before{
    content: "";
    border-right:1px solid #ddd;;
    margin-right:40px;
    height: 10px;
    vertical-align: middle;
    @media (max-width:1120px){
      margin-right:10px;
    }
  }
  @media (max-width:1000px){
    display:none;
  }
`

export default Header