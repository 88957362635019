import React from 'react'
import style from 'styled-components'
import CounterTopImg from '../../../../assets/img/countertops.jpg'

function FoundOutMore() {
  return (
    <MainContainer CounterTopImg={CounterTopImg}>
        <MoreContainer>
            <h3>FACTORY-DIRECT CABINETS, GLASS SHOWER SYSTEMS, COUNTERTOPS</h3>
            <h3>ordered directly from the manufacturers allowing us to maintain affordable prices and strict quality control.</h3>
            <div>
                <a href="/contactUs">FOUND OUT MORE</a>
            </div>
        </MoreContainer>
    </MainContainer>
  )
}

const MainContainer = style.div`
    padding:110px 0;
    background: url(${props=>props.CounterTopImg}) no-repeat center center;
    background-color: #f0f0f0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

`
const MoreContainer = style.div`
    max-width:1140px;
    margin:0 auto;
    text-align:center;
    h3{
        font-family: 'Roboto', sans-serif;
        font-size: 36px;
        font-weight: 700;
        line-height: 50px;
        color: #ffffff;
        letter-spacing: 1px;
        text-align: center;
        margin-bottom: 28px;
        text-shadow:2px 2px black;
    }
    div{
        margin-top:40px;
        a{
            background: #F48805;
            border: 1px solid #F48805;
            transition: all .5s ease;
            color: #ffffff;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 700;
            padding: 10px 30px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            text-decoration: none;
            cursor: pointer;
            &:hover{
                background: #bc7c32;
            }
        }
    }

`

export default FoundOutMore